import axios from 'axios';

class NBossConnection {
	constructor() {
		console.log('nBossConnection initialized');
		this.baseUrl = getNBossEndpoint();

		this.connection = axios.create({ baseURL: this.baseUrl });

		this.errorHandler = e => console.log(e);

		this.connection.interceptors.response.use(response => { return response; }, error => { error.isnBossConnectionError = true; return Promise.reject(error); });
	}

	setErrorHandler(errorHandler) { this.errorHandler = errorHandler; }
}

export const getNBossEndpoint = () => {
	const protocol = process.env.REACT_APP_API_SECURE === 'true' ? 'https' : 'http';
	const host = process.env.REACT_APP_API_HOST + (typeof process.env.REACT_APP_API_PORT !== 'undefined' ? (':' + process.env.REACT_APP_API_PORT) : '');
	const api = process.env.REACT_APP_API_NAME;
	const method = 'methods';

	return protocol + '://' + host + '/' + api + '/' + method + '/';
}

export default new NBossConnection();